import React from 'react'

import {Link} from 'gatsby'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import img4 from '../assets/images/blog/blog-img4.jpg'
import img5 from '../assets/images/blog/blog-img5.jpg'
import img6 from '../assets/images/blog/blog-img6.jpg'
import img7 from '../assets/images/blog/blog-img7.jpg'
import img8 from '../assets/images/blog/blog-img8.jpg'
import img10 from '../assets/images/blog/blog-img10.jpg'
import user1 from '../assets/images/team/giudi.jpg'
import user2 from '../assets/images/user2.jpg'
import user3 from '../assets/images/user3.jpg'
import user4 from '../assets/images/user4.jpg'
import user5 from '../assets/images/user5.jpg'
import user6 from '../assets/images/user6.jpg'
import { formatDateEu, hasTag } from '../utils/utils'


  
const Blog = ({ data, pageContext }) => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle={"TAG: "+ pageContext.tag}
                homePageText="Blog" 
                homePageUrl="/blog" 
                activePageText={pageContext.tag} 
            />
            <div className="blog-area ptb-100">
            <div className="container">
                <div className="row">
                {data.allStrapiArticle.edges.filter(hasTag(pageContext.tag)).map(article => (
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                            <a href={'/'+article.node.Slug}>
                                <img src={article.node.FeatureImage.localFile.children[0].fluid.src}></img>
                            </a>
                            </div>
                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user1} className="rounded-circle" alt="image" />
                                            <span>Gnucoop</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> {formatDateEu(article.node.PublishDate)}
                                    </li>
                                </ul>
                                <h3>
                                <a href={'/'+article.node.Slug}>
                                    {article.node.Title}                                    
                                </a>
                                </h3>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
            <Footer />
        </Layout>
    );
}

export default Blog;

export const query = graphql`
query TagQuery {
    allStrapiArticle(
      sort: {
        fields: [PublishDate]
        order: DESC
      }
    ) {
      edges {
        node {
          id
          Title
          Slug
          PublishDate
          tags {
            tag
          }
          FeatureImage {
            localFile {
                children {
                  ... on ImageSharp {
                    fluid (maxHeight: 250) {
                      src
                    }
                  }
                }
            }
          }
        }
      }
    }
  }

`
